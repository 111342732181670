import { Snackbar } from "@mui/material";
import { AlertProps } from "./interfcaes";

export const StyledAlert = ({
  color,
  onClose,
  open,
  duration = 3500,
  message,
  location = { vertical: "top", horizontal: "center" },
}: AlertProps) => {
  return (
    <Snackbar
      anchorOrigin={location}
      open={!!open}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: color,
        },
      }}
      onClose={onClose}
      message={message}
      autoHideDuration={duration}
    />
  );
};
