//Style
import { Divider } from "@mui/material";
import { OpenPopupButton } from "./AccountForm.styled";

//Common
import { FlexRow, FlexColumn } from "../../../common/containers";

//Interfaces
import { FullAccount } from "../../../../interfaces/models";
import { IFetchListResponse } from "../../../../interfaces/api/common";
import { IBreathingCycle } from "../../../../interfaces/models/breathing-cycle";

//Components
import { UserDetails, SessionRangesPopup, BreathingCyclePopup } from "..";

//Hooks
import { useCallback, useState } from "react";
import { useAsync } from "../../../../hooks/useAsync";

//Services
import { breathingCyclesApi } from "../../../../services/api";

export const AccountPageForm = ({
  fullAccount,
}: {
  fullAccount: FullAccount;
}) => {
  const [popupOpen, setPopupOpen] = useState({
    table: false,
    streak: false,
  });

  const openPopup = (name: "table" | "streak") => {
    setPopupOpen({ table: false, streak: false, [name]: true });
  };

  const { value: breathingCycles, status: breathingStatus } = useAsync<
    IFetchListResponse<IBreathingCycle>
  >(() => getAccounts(1), [fullAccount.account._id]);

  const getAccounts = useCallback(
    (page: number) => {
      const params = {
        skip: (page - 1) * 10,
        limit: 10,
        page: page,
      };

      return breathingCyclesApi.getUserBreathingCycle({
        userId: fullAccount.account._id,
        ...params,
      });
    },
    [fullAccount.account._id]
  );

  return (
    <>
      <FlexRow>
        <FlexColumn>
          <UserDetails fullAccount={fullAccount} />
        </FlexColumn>
        <Divider sx={{ margin: "0 2rem 0" }} orientation="vertical" flexItem />
        <FlexColumn>
          <OpenPopupButton onClick={() => openPopup("table")}>
            Show Breathing Cycles
          </OpenPopupButton>
          <OpenPopupButton onClick={() => openPopup("streak")}>
            Show Cycles Streak
          </OpenPopupButton>
        </FlexColumn>
      </FlexRow>
      <BreathingCyclePopup
        open={popupOpen.table}
        total={breathingCycles?.metadata.total || 0}
        isPending={breathingStatus === "pending"}
        breathingCycles={breathingCycles?.data || []}
        onClose={() => setPopupOpen((prev) => ({ ...prev, table: false }))}
        getItems={getAccounts}
      />
      <SessionRangesPopup
        userId={fullAccount.account._id}
        open={popupOpen.streak}
        onClose={() => setPopupOpen((prev) => ({ ...prev, streak: false }))}
      />
    </>
  );
};
