import { useState } from "react";

//Components
import { DateAndTime } from "components/dateAndTime";
import { FlexRow } from "../../../common/containers";
import { ShortIdToToCopy } from "components/shortIdToCopy";
import { Table, columnFields } from "../../../table/Table";

//Interfaces
import { IBreathingCycle } from "../../../../interfaces/models/breathing-cycle";

//Utils
import { StyledPopup } from "styledComponents";
import { getTimeDifference } from "../../../../utils";

const columns: columnFields[] = [
  {
    fieldName: "_id",
    columnName: "Session ID",
    costumeRenderColumn: ShortIdToToCopy,
  },
  {
    fieldName: "sessionStart",
    columnName: "Session Start",
    costumeRenderColumn: DateAndTime,
  },
  {
    fieldName: "sessionComplete",
    columnName: "Session End",
    costumeRenderColumn: DateAndTime,
  },
  {
    fieldName: "timeDiff",
    columnName: "Session Time",
    costumeRenderColumn: ({ row }) => {
      return (
        <FlexRow
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {row["sessionComplete"]
            ? getTimeDifference(row["sessionStart"], row["sessionComplete"])
            : "00:00"}
        </FlexRow>
      );
    },
  },
  {
    fieldName: "breathingCyclesCount",
    columnName: "Breathing Cycles",
  },
  {
    fieldName: "keysAwarded",
    columnName: "Key Awarded",
    type: "boolean",
  },
  {
    fieldName: "batteryStatus",
    columnName: "Battery Status ",
  },
  {
    fieldName: "deviceId",
    columnName: "Device ID",
    costumeRenderColumn: ShortIdToToCopy,
  },
  {
    fieldName: "createdAt",
    columnName: "Created at",
    type: "date",
  },
];

export const BreathingCyclePopup = ({
  open,
  onClose,
  breathingCycles,
  isPending,
  total,
  getItems,
}: {
  open?: boolean;
  breathingCycles: IBreathingCycle[];
  onClose: () => void;
  isPending: boolean;
  total: number;
  getItems: Function;
}) => {
  const [list, setList] = useState({
    data: breathingCycles || [],
    metadata: { page: 1, total },
  });

  const handleItemChange = (page: number) => {
    getItems(page).then((data: any) => setList(data));
  };
  return (
    <StyledPopup
      open={!!open}
      maxWidth={"xl"}
      onClose={onClose}
      title="Breathing Cycles"
    >
      {breathingCycles && (
        <Table<IBreathingCycle>
          list={list.data}
          columns={columns}
          getItems={handleItemChange}
          limit={10}
          total={total}
          page={list.metadata.page}
          loading={isPending}
        />
      )}
    </StyledPopup>
  );
};
