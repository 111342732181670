import { PersonSearch } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { shopifyApi } from "services/api/shopify-api";
import { StyledAlert } from "styledComponents";
import { palette } from "theme/theme";

export const LookFor = ({
  param,
  value,
  onDataReturn,
}: {
  param: string;
  value: string;
  onDataReturn: (data: any) => void;
}) => {
  const [open, setOpen] = useState<"error" | "success" | undefined>();
  return (
    <>
      <IconButton
        onClick={() =>
          shopifyApi.getCustomerByParam(param, value).then((data) => {
            setOpen(Object.values(data).length ? "success" : "error");
            onDataReturn(data);
          })
        }
        aria-label="fingerprint"
        color="info"
      >
        <PersonSearch />
      </IconButton>
      <StyledAlert
        open={!!open}
        color={
          open === "error" ? palette.error.light : open && palette.success.light
        }
        onClose={() => setOpen(undefined)}
        message={
          open === "error"
            ? "Could not find customer"
            : (open && "Customer successfully found") || ""
        }
      />
    </>
  );
};
