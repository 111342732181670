import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material";
import { fonts } from "theme/theme";

export const Button = styled(LoadingButton)<{ addBorder?: boolean }>(
  ({ addBorder }) => ({
    ...(addBorder && { border: "0.2rem solid #045A6B" }),
    borderRadius: "2rem",
    fontSize: "1.2rem",
    padding: "0.5rem 1rem 0.5rem",
    fontWeight: 900,
    color: "#0897a7",
    fontFamily: fonts.fontFamily.bold,
    height: "3rem",
    textTransform: "none",
  })
);
