import { ROUTES_PATHS } from "../../../../common";
import { ISubscription } from "../../../../interfaces/models";
import { accountsApi } from "../../../../services/api/accounts-api";
import { useAsync } from "../../../../hooks/useAsync";
import { NavigationField } from "components/navigationField";
import { FormComponentsProps } from "interfaces";

export const AppUserNav = ({ value }: FormComponentsProps<ISubscription>) => {
  const { value: account } = useAsync<any>(
    () => accountsApi.getAccountById({ id: value || "" }),
    [value]
  );

  return (
    <NavigationField
      value={value}
      linkValidator={!!account?.data}
      link={ROUTES_PATHS.Account.accountPage.replace(":id", value)}
    />
  );
};
