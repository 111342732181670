import { useAsync } from "../../../hooks/useAsync";
import { useCallback, useState } from "react";
import { subscriptionApi } from "../../../services/api";
import { SUBSCRIPTION_SORT_OPTIONS } from "../constants";

export const useSubscriptions = (limit: number) => {
  const [page, setPage] = useState(1);
  const [baseSearch, setSearch] = useState<string>();
  const [sortValue, setSortValue] = useState<any>("");
  const [selectedSortOptions, setSelectedSortOptions] = useState<any[]>([]);

  const getSubscriptions = useCallback(
    (page: number, search?: string) => {
      setSearch(search);
      setPage(page);
      const { sortBy, sortDir } = SUBSCRIPTION_SORT_OPTIONS.find(
        (item) => item.id === selectedSortOptions[0]
      )?.value || { sortBy: "createdAt", sortDir: "asc" }; //In case of undefine

      const params = {
        sortBy,
        sortDir,
        page,
        limit,
        ...(search && search.trim().length > 0 && { search }),
      };

      return subscriptionApi.getSubscription(params);
    },
    [selectedSortOptions]
  );

  const { value: subscriptions, status } = useAsync(
    () => getSubscriptions(page),
    []
  );

  const { value: subscriptionsCount } = useAsync(
    () =>
      subscriptionApi.getSubscriptionCount({
        ...(baseSearch &&
          baseSearch.trim().length > 0 && { search: baseSearch }),
      }),
    [baseSearch]
  );
  const [list, setList] = useState(subscriptions || []);

  const getData = useCallback(
    (page: number, search?: string) => {
      getSubscriptions(page, search).then((data: any) => setList(data));
    },
    [selectedSortOptions]
  );
  return {
    page,
    list,
    status,
    getData,
    sortValue,
    setSortValue,
    selectedSortOptions,
    setSelectedSortOptions,
    total: subscriptionsCount,
    sortOption: SUBSCRIPTION_SORT_OPTIONS,
  };
};
